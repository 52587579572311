import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/locateUsStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import Locate_us_icon from "ps-assets/img/locate_us_icon.png";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
var apiBaseUrl = process.env.API_URL;

const LocateUs = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var Alldata = data.allStrapiEntities.edges;

  var Title,
    RightCards = [],
    LeftCards = [];
  for (let i in Alldata) {
    let document = Alldata[parseInt(i)];
    let descArr = document.node.summary.split(`\n`);
    let desc = descArr.map((line, idx) => (
      <div key={idx}>
        <span>{line}</span>
        <br />
      </div>
    ));
    if (document.node.strapiId != 253) {
      if (document.node.strapiId < 261) {
        LeftCards.push(
          <Card blog className={classes.noShadow}>
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <p className={classes.description}>{desc}</p>
            </CardBody>
          </Card>
        );
      } else {
        RightCards.push(
          <Card blog className={classes.noShadow}>
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <p className={classes.description}>{desc}</p>
            </CardBody>
          </Card>
        );
      }
    } else {
      Title = (
        <div style={{ marginBottom: 50 }}>
          <h2 className={`${classes.title} `}>{`Locate Us`}</h2>
          <h5
            className={
              classes.description
            }>{`Feel free to visit us at any of the branch office nearby. You are always welcome. `}</h5>
        </div>
      );
    }
  }

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}>
        <Helmet key="helmetTags">
        <title>Locate US |StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `locate-us`}
          />
          <meta name="title" content="Locate US|StarHealth.in" />
          <meta name="twitter:title" content="Locate US|StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance Branch Location"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Locate US|StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance Branch Location" />
          <meta property="og:url" content={url + `locate-us`}/>
          <meta name="Locate US|StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance Branch Location" />
          <meta property="twitter:url" content={url + `locate-us`} />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={classes.container}
              style={{ paddingBottom: `70px` }}>
              <GridItem
                md={8}
                className={`${classes.mrAuto} ${classes.mlAuto} ${
                  classes.textCenter
                }`}>
                {Title}
              </GridItem>
              <GridItem md={12}>
                <CustomMap
                  id="branchmap"
                  searchMessage="Search Branch Office"
                  icon_url={Locate_us_icon}
                  icon_size={40}
                  category="branch"
                  url={`${apiBaseUrl}branch/search`}
                  cityUrl={`${apiBaseUrl}branch/city`}
                  helperText={`branch office`}
                  defaultView={`list`}
                  showTypeInListView
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
LocateUs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,

};
export default withStyles(termsStyle)(LocateUs);

export const LocateUsQuery = graphql`
  query LocateUs {
    allStrapiEntities(
      filter: { category: { in: ["terms_data", "terms_header"] } }
    ) {
      edges {
        node {
          strapiId
          title
          summary
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "locate_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp{
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
